import { ExpandLess, ExpandMore } from '@mui/icons-material';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { AnalyticsSetting } from '../../data/dto/setting-dto';
import { MenuActionEnum } from '../../data/enums/menu-action-enum';
import { MenuItemObj } from '../../data/models/menu-item';
import useAxiosSettings from '../../hooks/api/useAxiosSettings';
import { IRouteParams } from '../../hooks/useCustomNavigation';
import useDrawer from '../../hooks/useDrawer';
import useHasAuthorization from '../../hooks/useHasAuthorization';
import { useAuth } from '../../providers/authentication/AuthProvider';

const drawerWidth = 240;
const topBarHeight = 60;
const footerHeight = 90;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  top: topBarHeight,
  // eslint-disable-next-line @typescript-eslint/quotes
  height: `calc(100% - ${footerHeight}px - ${topBarHeight}px )`,
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  top: topBarHeight,
  // eslint-disable-next-line @typescript-eslint/quotes
  height: `calc(100% - ${footerHeight}px - ${topBarHeight}px )`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    width: `calc(${theme.spacing(8)} + 1px)`
  },
  [theme.breakpoints.down('sm')]: {
    width: '0'
  }
});
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  loggedUser: string;
  currentLang: string;
  open: boolean;
  drawerItems: MenuItemObj[];
  actionCallBack: (action: MenuActionEnum, params?: IRouteParams[]) => void;
  setDirtyDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  isDirty: boolean;
}

const Main = styled('main', { shouldForwardProp: (prop): boolean => prop !== 'open' })<{
  open: boolean;
}>(
  ({ theme, open }): CSSObject => ({
    flexGrow: 1,
    position: 'relative',
    overflowY: 'auto',
    // eslint-disable-next-line @typescript-eslint/quotes
    padding: theme.spacing(3),
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    left: `calc(${theme.spacing(7)} + 1px)`,
    height: '100%',
    width: `calc(100% - ${theme.spacing(8)} + 1px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      left: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
        overflowY: 'hidden'
      }
    }),
    ...(!open && {
      [theme.breakpoints.down('sm')]: {
        left: 0,
        width: '100%'
      }
    })
  })
);

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop): boolean => prop !== 'open'
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
})(
  ({ theme, open }): CSSObject => ({
    width: '600px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

const MiniDrawer: React.FC<Props & React.PropsWithChildren> = ({
  drawerItems,
  open,
  children,
  actionCallBack
}): JSX.Element => {
  const { hasAnyPolicy } = useHasAuthorization();
  const { toggleDrawer } = useDrawer();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const menuItems = _.filter(drawerItems, (item): boolean => hasAnyPolicy(item.roles ?? [], item.permissions ?? []));
  const { get } = useAxiosSettings();
  const { groupUid } = useAuth();
  const [selected, setSelected] = React.useState(true);

  const handleClick = (): void => {
    setSelected(!selected);
    if (!open) {
      toggleDrawer();
    }
  };

  useEffect((): void => {
    _.map(menuItems, (item: MenuItemObj): MenuItemObj => {
      if (item.id === 'analytics') {
        get(groupUid).then((res): void => {
          item.children = res.analytics.map((it): AnalyticsSetting => it);
        });
      }
      return item;
    });
  }, []);

  const listItemClick = (action: MenuActionEnum, params?: IRouteParams[]): void => {
    if (matches) {
      toggleDrawer();
    }

    actionCallBack(action, params);
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Divider />
          <List>
            {menuItems.map(
              (drawerItem): JSX.Element => (
                <div key={drawerItem.id}>
                  {_.isEmpty(drawerItem.children) ? (
                    <ListItem key={drawerItem.id} disablePadding>
                      <ListItemButton onClick={(): void => listItemClick(drawerItem.action)}>
                        <ListItemIcon>{drawerItem.image}</ListItemIcon>
                        <ListItemText
                          primary={
                            <Translation ns={drawerItem?.namespace ?? 'common'}>
                              {(t): string => t(drawerItem.text)}
                            </Translation>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  ) : (
                    <div key={drawerItem.id}>
                      <ListItemButton key={drawerItem.id} onClick={handleClick}>
                        <ListItemIcon style={{ display: 'flex', alignItems: 'center' }}>
                          {drawerItem.image}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Translation ns={drawerItem?.namespace ?? 'common'}>
                              {(t): string => t(drawerItem.text)}
                            </Translation>
                          }
                        />
                        {selected ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={selected} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {_.map(drawerItem.children, (x: AnalyticsSetting, index: number): JSX.Element => {
                            return (
                              <ListItemButton
                                key={`${x.reportId}${index}`}
                                sx={{
                                  pl: 4,
                                  display: (_theme): string => {
                                    if (!open) {
                                      return 'none';
                                    }
                                    return 'flex';
                                  }
                                }}
                                onClick={(): void =>
                                  listItemClick(drawerItem.action, [{ key: ':id', value: x.reportId }])
                                }>
                                <ListItemIcon>
                                  <BarChartIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={<span>{x.name}</span>} />
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </Collapse>
                    </div>
                  )}
                </div>
              )
            )}
          </List>
        </Drawer>
        <Box
          sx={{
            position: 'fixed',
            top: topBarHeight,
            bottom: footerHeight,
            width: '100%',
            height: `calc(100% - ${footerHeight}px - ${topBarHeight}px )`
          }}>
          <Main open={open}>{children}</Main>
        </Box>
      </Box>
    </>
  );
};
export default MiniDrawer;
