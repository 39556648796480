import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import logo from '../../../assets/logo-cloudmind.svg';
import CustomBreadcrumbs from '../../../components/breadcrumbs/CustomBreadcrumbs';
import AccountMenuItems from '../../../shared/data/constants/AccountMenuItems';
import DrawerItems from '../../../shared/data/constants/DrawerItems';
import { MenuActionEnum } from '../../../shared/data/enums/menu-action-enum';
import { RoutesEnum } from '../../../shared/data/enums/routes-enum';
import useCustomNavigation, { IRouteParams } from '../../../shared/hooks/useCustomNavigation';
import useUpdateDomTitle from '../../../shared/hooks/useUpdateDomTitle';
import BaseLayout from '../../../shared/layout/base-layout/BaseLayout';
import { useAuth } from '../../../shared/providers/authentication/AuthProvider';
import { useConfig } from '../../../shared/providers/config/ConfigProvider';
import { useLang } from '../../../shared/providers/utils/LanguageProvider';
import { useLoading } from '../../../shared/providers/utils/LoadingSpinnerProvider';
import { useSnack } from '../../../shared/providers/utils/SnackBarProvider';

const PageWrapper: React.FC = (): JSX.Element => {
  const { user, logout } = useAuth();
  const { lang, changeLanguage, supportedLanguages } = useLang();
  const { navigate, navigateToWithParams } = useCustomNavigation();
  const { appVersion, appName } = useConfig();
  const { updateTitle } = useUpdateDomTitle(appName);
  const { showLoader, hideLoader } = useLoading();
  const { openErrorSnackBar } = useSnack();
  const { t } = useTranslation(['users', 'common']);

  const handleLogoutClick = (): void => {
    showLoader();
    logout()
      .then((): void => {
        hideLoader();
        navigate(RoutesEnum.LANDING_PAGE, true, RoutesEnum.DASHBOARD);
      })
      .catch((_err): void => {
        hideLoader();
        openErrorSnackBar(t('errors.page_wrapper.logout'));
      });
  };

  const actionRoutesMap = {
    [MenuActionEnum.LOGOUT_ACTION]: handleLogoutClick,
    [MenuActionEnum.PROFILE_ACTION]: RoutesEnum.PROFILE_USER,
    [MenuActionEnum.HOME_ACTION]: RoutesEnum.DASHBOARD,
    [MenuActionEnum.USERS_ACTION]: RoutesEnum.LIST_USERS,
    [MenuActionEnum.GROUP_ACTION]: RoutesEnum.LIST_GROUP,
    [MenuActionEnum.API_KEY_ACTION]: RoutesEnum.LIST_API_KEY,
    [MenuActionEnum.INGESTION_ACTION]: RoutesEnum.LIST_INGESTION,
    [MenuActionEnum.FULFILLMENT_ACTION]: RoutesEnum.LIST_FULFILLMENT,
    [MenuActionEnum.ANALYTICS_ACTION]: RoutesEnum.DETAIL_ANALYTICS,
    [MenuActionEnum.BOTBUILDER_ACTION]: RoutesEnum.PAGE_NOT_FOUND
  };

  const actionCallBack = (action: MenuActionEnum, params?: IRouteParams[]): void => {
    const route = actionRoutesMap[action] || RoutesEnum.DASHBOARD;
    if (typeof route === 'function') {
      route();
    } else {
      if (params) {
        navigateToWithParams(route, params);
      } else {
        navigate(route);
      }
    }
  };

  const handleChangeLanguage = (language: string): void => {
    changeLanguage(language);
  };

  useEffect((): void => {
    updateTitle(appName);
  }, []);

  return (
    <BaseLayout
      currentLang={lang}
      languageClickHandler={handleChangeLanguage}
      loggedUser={user?.email ?? ''}
      actionCallBack={actionCallBack}
      logo={logo}
      appVersion={appVersion}
      drawerItems={DrawerItems}
      supportedLanguages={supportedLanguages}
      accountMenuItems={AccountMenuItems}>
      <CustomBreadcrumbs />
      <Outlet />
    </BaseLayout>
  );
};

export default PageWrapper;
