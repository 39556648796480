import { User } from 'firebase/auth';
import _ from 'lodash';
import { UserPermissions } from '../enums/permissions-enum';
import { UserRoles } from '../enums/roles-enum';
import { IFirebaseUser } from '../models/user';
interface Auths {
  role: UserRoles;
  groupId: string;
  permissions: UserPermissions[];
  groupName: string;
}

interface CustomAttributes {
  auths: Auths;
}

interface ReloadUserInfo {
  customAttributes: string;
}

interface UserImpl extends User {
  accessToken: string;
  reloadUserInfo: ReloadUserInfo;
}

const mapCustomAttribute = (reloadInfo: Partial<ReloadUserInfo> | null): CustomAttributes => {
  if (!_.isNil(reloadInfo?.customAttributes)) {
    const customAttributes: CustomAttributes = JSON.parse(reloadInfo?.customAttributes ?? '{}');
    return customAttributes;
  }
  return {
    auths: {
      role: UserRoles.USER,
      groupId: '',
      permissions: [],
      groupName: ''
    }
  };
};

export const mapFirebaseUserToUser = (user: Partial<UserImpl>): IFirebaseUser => {
  const customAttributes = mapCustomAttribute(user.reloadUserInfo ?? null);
  return {
    displayName: user.displayName ?? '',
    email: user.email ?? '',
    emailVerified: user.emailVerified || false,
    firebaseUid: user.uid || '',
    firestoreUid: '',
    lastAccessDate: user?.metadata?.lastSignInTime ?? '',
    token: user.accessToken || '',
    role: customAttributes.auths.role,
    permissions: customAttributes.auths.permissions,
    groupUid: customAttributes.auths.groupId,
    groupName: customAttributes.auths.groupName
  };
};
