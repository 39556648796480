import { sendPasswordResetEmail, signInWithEmailAndPassword, signOut, User } from 'firebase/auth';
import _, { isNil } from 'lodash';
import React, { Context, PropsWithChildren, useContext } from 'react';
import { UserPermissions } from '../../data/enums/permissions-enum';
import { UserRoles } from '../../data/enums/roles-enum';
import { SessionStorageKey } from '../../data/enums/session-storage-key';
import { mapFirebaseUserToUser } from '../../data/mappers/firebaseuser-to-user';
import { IFirebaseUser } from '../../data/models/user';
import useAxiosGroups from '../../hooks/api/useAxiosGroups';
import useAxiosUsers from '../../hooks/api/useAxiosUsers';
import { loggedIn, loggedOut } from '../../store/actions/authentication-actions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { currentAuthenticationState } from '../../store/selectors/authentication-selector';
import { useFirebase } from './FirebaseAppProvider';

interface IAuthProvider {
  user: IFirebaseUser | null;
  groupUid: string;
  role: UserRoles;
  permissions: UserPermissions[];
  isAuthenticated: () => boolean;
  login: (email: string, password: string) => Promise<User>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
}

const AuthContext: Context<IAuthProvider> = React.createContext<IAuthProvider>({} as unknown as IAuthProvider);

const useAuthProvider = (): IAuthProvider => {
  const authenticationState = useAppSelector(currentAuthenticationState);
  const dispatch = useAppDispatch();
  const { auth } = useFirebase();
  const { getGroupById } = useAxiosGroups();
  const { getUserByUid } = useAxiosUsers();

  const login = async (email: string, password: string): Promise<User> => {
    if (isNil(auth)) {
      throw new Error('Missing auth firebase');
    }
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const user = mapFirebaseUserToUser(res.user);
      sessionStorage.setItem(SessionStorageKey.TOKEN, window.btoa(user.token));
      const userWithProfileInfo = await getUserProfile(user.firebaseUid, user);
      const userWithGroupInfo = await getGroupInformation(user.groupUid, userWithProfileInfo);
      sessionStorage.setItem(SessionStorageKey.AUTHENTICATION_STATE, window.btoa(JSON.stringify(userWithGroupInfo)));
      dispatch(loggedIn({ firebaseUser: userWithGroupInfo }));
      return res.user;
    } catch (err: unknown) {
      throw new Error((err as Error).message);
    }
  };

  const getUserProfile = async (userId: string, user: IFirebaseUser): Promise<IFirebaseUser> => {
    const userProfile = await getUserByUid(userId);
    user.firstName = userProfile.firstname;
    user.lastName = userProfile.lastname;
    user.firestoreUid = userProfile.id ?? '';
    return user;
  };
  const getGroupInformation = async (groupUid: string, user: IFirebaseUser): Promise<IFirebaseUser> => {
    const group = await getGroupById(groupUid);
    user.group = group;
    user.apiKey = group.apikey;
    return user;
  };

  const logout = async (): Promise<void> => {
    if (isNil(auth)) {
      throw new Error('Missing auth firebase');
    }
    try {
      await signOut(auth);
      sessionStorage.removeItem(SessionStorageKey.AUTHENTICATION_STATE);
      sessionStorage.removeItem(SessionStorageKey.TOKEN);
      dispatch(loggedOut());
    } catch (err: unknown) {
      throw new Error((err as Error).message);
    }
  };

  const resetPassword = async (email: string): Promise<void> => {
    if (isNil(auth)) {
      throw new Error('Missing auth firebase');
    }
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (err: unknown) {
      throw new Error((err as Error).message);
    }
  };

  const isAuthenticated = (): boolean => {
    return !_.isNil(authenticationState.firebaseUser);
  };

  return {
    user: authenticationState.firebaseUser,
    groupUid: authenticationState.firebaseUser?.groupUid ?? '',
    role: authenticationState.firebaseUser?.role ?? UserRoles.USER,
    permissions: authenticationState.firebaseUser?.permissions ?? [],
    isAuthenticated,
    login,
    logout,
    resetPassword
  };
};

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const provider = useAuthProvider();

  return <AuthContext.Provider value={provider}>{children}</AuthContext.Provider>;
};

export const useAuth = (): IAuthProvider => useContext(AuthContext);
