import { Box } from '@mui/material';
import { MenuActionEnum } from '../../data/enums/menu-action-enum';
import { MenuItemObj } from '../../data/models/menu-item';
import AccountMenu from './account-menu/AccountMenu';
import HamburgerMenuIcon from './hamburger-icon/HamburgerMenuIcon';
import LanguageBtn from './language-btn/LanguageBtn';
import LogoImage from './logo/LogoImage';

interface TopbarAuthenticatedProps {
  actionCallBack: (action: MenuActionEnum) => void;
  loggedUser: string;
  currentLang: string;
  languageClickHandler: (lang: string) => void;
  hamburgerIconClickHandler: () => void;
  supportedLanguages: string[];
  accountMenuItems: MenuItemObj[];
}

const TopBarAuthenticated: React.FC<TopbarAuthenticatedProps> = ({
  actionCallBack,
  loggedUser,
  currentLang,
  languageClickHandler,
  hamburgerIconClickHandler,
  supportedLanguages,
  accountMenuItems
}): JSX.Element => {
  return (
    <>
      <Box sx={{ alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <HamburgerMenuIcon hamburgerIconClick={hamburgerIconClickHandler} />
          <LogoImage />
        </Box>
      </Box>
      <Box sx={{ alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <LanguageBtn
            currentLang={currentLang}
            languageClickHandler={languageClickHandler}
            supportedLanguages={supportedLanguages}
          />
          <AccountMenu loggedUser={loggedUser} accountMenuItems={accountMenuItems} actionCallBack={actionCallBack} />
        </Box>
      </Box>
    </>
  );
};

export default TopBarAuthenticated;
