import { AppBar, Toolbar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';

interface IFooterProps {
  logo: string;
  version: string;
}

const Footer: FC<IFooterProps> = (props): JSX.Element => {
  return (
    <Box sx={{ pb: 7 }}>
      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, height: '90px', width: '100%' }}>
        <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '100%' }}>
          <Box sx={{ alignItems: 'center' }}>
            <Box sx={{ height: '100%' }}>
              <Typography component="div" variant="body2">
                Powered By
              </Typography>
              <Box>
                <img src={props.logo} height="26px" width="128px" alt="logo" />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: 'center'
            }}>
            <Typography variant="body1">{props.version}</Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Footer;
