import _ from 'lodash';

interface IUtils {
  getEnumKeyByValue: <T extends { [key: string]: string }>(value: string, enumeration: T) => string | undefined;
  enumPossibleValues: <T extends { [key: string]: string }>(enumeration: T) => T[];
}

export default function useUtils(): IUtils {
  const getEnumKeyByValue = <T extends { [key: string]: string }>(
    value: string,
    enumeration: T
  ): string | undefined => {
    const indexOfValue = _.indexOf(_.values(enumeration), value);
    return _.keys(enumeration)[indexOfValue];
  };

  const enumPossibleValues = <T extends { [key: string]: string }>(enumeration: T): T[] => {
    const a = _.mapKeys(enumeration, (_key: string, value: string): string => value);
    return _.toArray(a as unknown) as unknown as T[];
  };

  return {
    getEnumKeyByValue,
    enumPossibleValues
  };
}
