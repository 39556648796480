export enum UserPermissions {
  FULFILLMENT_VIEWER = 'FULFILLMENT_VIEWER',
  FULFILLMENT_EDITOR = 'FULFILLMENT_EDITOR',
  DOC_INGESTION_VIEWER = 'DOC_INGESTION_VIEWER',
  DOC_INGESTION_EDITOR = 'DOC_INGESTION_EDITOR',
  BOTBUILDER_VIEWER = 'BOTBUILDER_VIEWER',
  BOTBUILDER_EDITOR = 'BOTBUILDER_EDITOR',
  ANALYTICS_VIEWER = 'ANALYTICS_VIEWER',
  ANALYTICS_EDITOR = 'ANALYTICS_EDITOR'
}
