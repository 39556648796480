import { Components, createTheme, PaletteOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import styles from './variable.module.scss';

const breakpoints = createBreakpoints({});

const palette: PaletteOptions = {
  primary: {
    main: styles.primaryColor ?? ''
  },
  secondary: {
    main: styles.secondaryColor ?? ''
  },
  error: {
    main: styles.errorFeedback ?? ''
  },
  action: {
    hover: styles.secondaryColor,
    disabledBackground: styles.disabled,
    disabled: styles.white
  },
  background: {
    default: styles.backgroundColor,
    paper: styles.sidebarBlue
  },
  text: {
    primary: styles.white,
    secondary: styles.black,
    disabled: styles.white
  },
  common: {
    black: styles.black,
    white: styles.white
  },
  grey: {
    '100': styles.grey100,
    '200': styles.grey200,
    '300': styles.grey300,
    '500': styles.grey500,
    '600': styles.grey600
  }
};

const typography: TypographyOptions = {
  fontFamily: styles.fontFamily,
  fontWeightMedium: styles.fontWeightMd,
  fontWeightBold: styles.fontWeightMdBold,
  [breakpoints.down('sm')]: {
    fontSize: styles.fontSizeSm
  },
  [breakpoints.up('sm')]: {
    fontSize: styles.fontSizeMdLg
  },
  h1: {
    fontWeight: styles.fontWeightH1,
    [breakpoints.down('sm')]: {
      fontSize: styles.fontSizeH1Sm
    },
    [breakpoints.up('sm')]: {
      fontSize: styles.fontSizeH1MdLg
    }
  },
  body1: {
    fontSize: styles.fontSizeParagraphSm,
    lineHeight: styles.lineHeight,
    fontWeight: styles.fontWeightMd
  },
  body2: {
    fontSize: styles.fontSizeParagraphMd,
    lineHeight: styles.lineHeight,
    fontWeight: styles.fontWeightMdBold
  }
};

const components: Components = {
  MuiPaper: {
    variants: [
      {
        props: { color: 'secondary' },
        style: { backgroundColor: styles.white }
      }
    ]
  },
  MuiButton: {
    styleOverrides: {
      root: {
        ':hover': {
          backgroundColor: styles.accentBlue,
          color: styles.white
        }
      }
    },
    variants: [
      {
        props: { size: 'large' },
        style: {
          height: styles.buttonLgHeight,
          width: styles.buttonLgWidth,
          fontWeight: styles.fontWeightMdBold,
          fontSize: styles.buttonLgFontSize
        }
      },
      {
        props: { size: 'medium' },
        style: {
          height: styles.buttonMdHeight,
          width: styles.buttonMdWidth,
          fontWeight: styles.fontWeightMdBold,
          fontSize: styles.buttonMdFontSize
        }
      },
      {
        props: { size: 'small' },
        style: {
          height: styles.buttonSmHeight,
          width: styles.buttonSmWidth,
          fontWeight: styles.fontWeightMdBold,
          fontSize: styles.buttonSmFontSize
        }
      },
      {
        props: { color: 'primary' },
        style: {
          ':hover': {
            backgroundColor: styles.accentBlue
          }
        }
      },
      {
        props: { color: 'secondary' },
        style: {
          ':hover': {
            backgroundColor: styles.secondaryColor
          }
        }
      }
    ]
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          '&:hover > fieldset': {
            border: `2px solid ${styles.textFieldColor}`
          },
          '&.Mui-focused > fieldset': {
            borderColor: styles.accentBlue
          },
          '&.Mui-disabled > fieldset': {
            borderColor: styles.textFieldColorDisabled
          }
        }
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        color: styles.textFieldColor,
        '& .Mui-disabled': {
          borderColor: styles.textFieldColorDisabled,
          color: styles.textFieldColorDisabled,
          '&.Mui-disabled': {
            WebkitTextFillColor: styles.textFieldColorDisabled
          }
        }
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: styles.textFieldColor,
        '&.Mui-focused': {
          color: styles.accentBlue
        },
        '&.Mui-disabled': {
          color: styles.textFieldColorDisabled
        }
      }
    }
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        borderColor: styles.textFieldColor,
        '& .MuiOutlinedInput-root': {
          '&:hover > fieldset': {
            border: `2px solid ${styles.textFieldColor}`
          },
          '&.Mui-focused > fieldset': {
            borderColor: styles.accentBlue
          },
          '&.Mui-disabled > fieldset': {
            borderColor: styles.textFieldColorDisabled
          }
        }
      }
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        color: styles.black,
        '&.Mui-disabled > .Mui-disabled': {
          color: styles.textFieldColorDisabled
        }
      }
    }
  },
  MuiSvgIcon: {
    variants: [
      {
        props: { color: 'primary' },
        style: {
          color: styles.white
        }
      }
    ]
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        top: 'auto'
      },
      paper: {
        top: 'auto'
      }
    }
  },
  MuiLink: {
    variants: [
      {
        props: { color: 'primary' },
        style: {
          color: styles.accentBlue
        }
      }
    ]
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        backgroundColor: 'gray'
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiDialog-paper': {
          backgroundColor: styles.white,
          color: styles.black
        }
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        color: styles.white
      }
    }
  }
};

export const appTheme = createTheme({
  palette,
  typography,
  components,
  breakpoints
});
