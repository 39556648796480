import { Box, Button, Container, InputBase, InputBaseProps, SelectChangeEvent, Skeleton, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  GridActionsCellItemProps,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowHeightReturnValue,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridValidRowModel,
  useGridApiContext
} from '@mui/x-data-grid';
import { GridColDef, GridColTypeDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BasicDataGrid, { CustomPaginationModel } from '../../../components/data-grid/BaseDataGrid';
import { UserPermissions } from '../../../shared/data/enums/permissions-enum';
import { ResFulfill } from '../../../shared/data/models/fulfillment-response.model';
import useAxiosFulfillment from '../../../shared/hooks/api/useAxiosFulfillment';
import useHasAuthorization from '../../../shared/hooks/useHasAuthorization';
import { useSnack } from '../../../shared/providers/utils/SnackBarProvider';
import ActionsResponse from './ActionsResponse';
import BackModal from './BackModal';
import SubmitModal from './SubmitModal';

function EditTextarea(props: GridRenderEditCellParams): JSX.Element {
  const { id, field, value, colDef, hasFocus } = props;
  const [valueState, setValueState] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
  const apiRef = useGridApiContext();

  React.useLayoutEffect((): void => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = React.useCallback((el: HTMLElement | null): void => {
    setAnchorEl(el);
  }, []);

  const handleChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
    (event): void => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event);
    },
    [apiRef, field, id]
  );

  return (
    <div style={{ position: 'relative', alignSelf: 'flex-start', width: '100%' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0
        }}
      />
      {anchorEl && (
        <InputBase
          multiline
          value={valueState}
          fullWidth
          rows={5}
          sx={{ textarea: { resize: 'vertical' }, p: 1 }}
          onChange={handleChange}
          inputRef={(ref): void => setInputRef(ref)}
        />
      )}
    </div>
  );
}

const multilineColumn: GridColTypeDef = {
  type: 'string',
  renderEditCell: (params): JSX.Element => <EditTextarea {...params} />
};

const SkeletonGroup: React.FC = (): JSX.Element => {
  return (
    <Stack spacing={1}>
      {Array.of(1, 2, 3, 4, 5).map(
        (x): JSX.Element => (
          <Skeleton key={x} variant="rounded" height={60} />
        )
      )}
    </Stack>
  );
};

export const EditIntent: React.FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const { intent, language } = useParams();
  const [rows, setRows] = React.useState<ResFulfill[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const navigate = useNavigate();
  const { getAllResponses, editResponse, editTemplate } = useAxiosFulfillment();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [offset, setOffset] = useState<number>(0);
  const [nRows, setNRows] = useState<number>(0);
  const { openErrorSnackBar, openSuccessSnackBar } = useSnack();
  const { hasAnyPermissions } = useHasAuthorization();
  const [originalElements, setOriginalElements] = useState<ResFulfill[]>([]);
  const { t } = useTranslation(['fulfillment']);
  const [open, setOpen] = React.useState(false);
  const [openBackModal, setOpenBackModal] = React.useState(false);

  const [env, setEnv] = React.useState('test');

  useEffect((): (() => void) => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): string => {
      event.preventDefault();
      const confirmationMessage = 'Sei sicuro di voler lasciare la pagina?';

      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;

      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return (): void => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate]);

  useEffect((): void => {
    if (intent !== undefined && language !== undefined) {
      getAllResponses(intent, language)
        .then((res: ResFulfill[]): void => {
          const data = res.map((x: ResFulfill): ResFulfill => {
            return {
              id: crypto.randomUUID(),
              link: x.link,
              param: x.param,
              text: x.text,
              isEdit: false
            };
          });
          setNRows(data.length);
          setRows(data);
          setOriginalElements(data);
        })
        .catch((): void => {
          openErrorSnackBar(t('snack_messagge.error_load'));
          setRows([]);
        })
        .finally((): void => setIsLoading(false));
    }
  }, []);

  const processRowUpdate = (newRow: GridValidRowModel, oldRow: GridValidRowModel): GridValidRowModel => {
    if (_.isEqual(newRow, oldRow)) {
      return newRow;
    }
    newRow.isEdit = true;
    setRows(rows.map((row: ResFulfill): ResFulfill => (row.id === newRow.id ? (newRow as ResFulfill) : row)));
    return newRow;
  };

  const submitAll = (): void => {
    if (intent && language) {
      navigate(-1);
      rows
        .filter((x): boolean | undefined => x.isEdit)
        .forEach((x): Promise<ResFulfill> => editResponse(x, intent, language));
      editTemplate(intent, env, language);
      openSuccessSnackBar(t('snack_messagge.start_upload').toString());
    }
  };

  const onChangeFreeSearch = (search: string): void => {
    if (_.isEmpty(search)) {
      setRows(originalElements);
    } else {
      const filteredList: ResFulfill[] = originalElements.filter(
        (el: ResFulfill): boolean =>
          el.param.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          el.text.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
      setRows(filteredList);
    }
  };

  const handleEditClick = (id: GridRowId) => (): void => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => (): void => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => (): void => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
  };

  const columns: GridColDef[] = [
    {
      field: 'param',
      headerName: t('response.param').toString(),
      width: 150
    },
    {
      field: 'link',
      headerName: t('response.link').toString(),
      width: 200,
      editable: hasAnyPermissions([UserPermissions.FULFILLMENT_EDITOR]),
      ...multilineColumn
    },
    {
      field: 'text',
      headerName: t('response.text').toString(),
      width: 700,
      editable: hasAnyPermissions([UserPermissions.FULFILLMENT_EDITOR]),
      ...multilineColumn
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }): React.ReactElement<GridActionsCellItemProps>[] => {
        return ActionsResponse(
          id,
          rowModesModel,
          hasAnyPermissions([UserPermissions.FULFILLMENT_EDITOR]),
          handleEditClick,
          handleCancelClick,
          handleSaveClick
        );
      }
    }
  ];

  return (
    <Container>
      <Box sx={{ width: '100%' }}>
        {isLoading ? (
          <SkeletonGroup />
        ) : (
          <>
            <SubmitModal
              handleClose={(): void => setOpen(false)}
              handleEnvChange={(event: SelectChangeEvent<typeof env>): void => setEnv(event.target.value)}
              env={env}
              open={open}
              submitAll={submitAll}></SubmitModal>
            <BackModal handleClose={(): void => setOpenBackModal(false)} open={openBackModal}></BackModal>
            <BasicDataGrid
              enableFreeSearch={true}
              onChangeFreeSearch={onChangeFreeSearch}
              getRowHeight={(): GridRowHeightReturnValue => 'auto'}
              columns={columns}
              editMode="row"
              rowCount={nRows}
              getRowId={(row): string => row.id}
              rows={rows}
              isLoading={false}
              paginationModel={{ pageSize, page }}
              paginationMode="client"
              onChangePagination={(model: CustomPaginationModel): void => {
                setOffset(model.offset);
                setPage(model.page);
                setPageSize(model.pageSize);
              }}
              page={page}
              offset={offset}
              disableRowSelectionOnClick
              rowModesModel={rowModesModel}
              onRowModesModelChange={(newRowModesModel: GridRowModesModel): void => setRowModesModel(newRowModesModel)}
              onRowEditStop={(params, event): void => {
                if (params.reason === GridRowEditStopReasons.rowFocusOut) {
                  event.defaultMuiPrevented = true;
                }
              }}
              processRowUpdate={processRowUpdate}
            />
            <Grid container direction="row" justifyContent="space-between" marginTop={'20px'}>
              <Button
                onClick={(): void => {
                  if (rows.filter((x): boolean | undefined => x.isEdit).length > 0) {
                    return setOpenBackModal(true);
                  }
                  navigate(-1);
                }}
                variant="contained"
                color="error">
                {t('button.back').toString()}
              </Button>
              <Button onClick={(): void => setOpen(true)} sx={{ width: '10rem' }} variant="contained" color="success">
                {t('button.save').toString()}
              </Button>
            </Grid>
          </>
        )}
      </Box>
    </Container>
  );
};
