import { Logout } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { MenuActionEnum } from '../../data/enums/menu-action-enum';
import { MenuItemObj } from '../../data/models/menu-item';

const AccountMenuItems: MenuItemObj[] = [
  {
    id: 'profile',
    image: <AccountCircleIcon sx={{ mr: 2 }} color="primary" />,
    text: 'account.profile',
    action: MenuActionEnum.PROFILE_ACTION
  },
  {
    id: 'logout',
    image: <Logout sx={{ mr: 2 }} color="primary" />,
    text: 'account.logout',
    action: MenuActionEnum.LOGOUT_ACTION
  }
];

export default AccountMenuItems;
