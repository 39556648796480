import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IBackModalProps {
  handleClose: () => void;
  open: boolean;
}

export default function BackModal(props: IBackModalProps): JSX.Element {
  const { handleClose, open } = props;
  const { t } = useTranslation(['fulfillment']);
  const navigate = useNavigate();

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('back_modal.title').toString()}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('back_modal.dialog_text').toString().split('\n')[0]} <br></br>
            {t('back_modal.dialog_text').toString().split('\n')[1]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('button.back').toString()}</Button>
          <Button onClick={(): void => navigate(-1)}>{t('button.confirm').toString()}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
