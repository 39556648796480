import { SessionStorageKey } from '../../data/enums/session-storage-key';
import { IFirebaseUser } from '../../data/models/user';

export interface AuthenticationState {
  firebaseUser: IFirebaseUser | null;
}

export const initialAuthState: AuthenticationState = {
  firebaseUser:
    sessionStorage.getItem(SessionStorageKey.AUTHENTICATION_STATE) !== null
      ? JSON.parse(window.atob(sessionStorage.getItem(SessionStorageKey.AUTHENTICATION_STATE) as string))
      : null
};
