import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { EnvironmentEnum } from '../../../shared/data/enums/environment-enum';

interface ISubmitModalProps {
  handleClose: () => void;
  handleEnvChange: (event: SelectChangeEvent<string>) => void;
  env: string;
  open: boolean;
  submitAll: () => void;
}

export default function SubmitModal(props: ISubmitModalProps): JSX.Element {
  const { handleClose, handleEnvChange, env, open, submitAll } = props;
  const { t } = useTranslation(['fulfillment']);
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('button.save').toString()}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('submit_modal.dialog_text').toString()}</DialogContentText>
          <FormControl sx={{ mt: 2, minWidth: 120 }}>
            <InputLabel htmlFor="environment">{t('submit_modal.select_label').toString()}</InputLabel>
            <Select autoFocus label="environment" value={env} onChange={handleEnvChange}>
              {Object.values(EnvironmentEnum).map(
                (x): JSX.Element => (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('button.back').toString()}</Button>
          <Button
            onClick={(): void => {
              submitAll();
              handleClose();
            }}>
            {t('button.save').toString()}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
