import React, { PropsWithChildren } from 'react';
import ErrorPage from '../../../pages/error/error-page/ErrorPage';
import { UserPermissions } from '../../data/enums/permissions-enum';
import { UserRoles } from '../../data/enums/roles-enum';
import useHasAuthorization from '../../hooks/useHasAuthorization';

interface IProtectedRoute extends PropsWithChildren {
  roles?: UserRoles[];
  permissions?: UserPermissions[];
}

const ProtectedRoute: React.FC<IProtectedRoute> = ({ children, roles = [], permissions = [] }): JSX.Element => {
  const { hasAnyPolicy } = useHasAuthorization();
  return <React.Fragment>{hasAnyPolicy(roles, permissions) ? children : <ErrorPage />}</React.Fragment>;
};

export default ProtectedRoute;
