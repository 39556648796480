import { useTheme } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import React from 'react';

export interface ICustomToolbarProps {
  isColumnsFilterEnabled?: boolean;
  isFilterEnabled?: boolean;
  isDensityEnabled?: boolean;
  isExportingEnabled?: boolean;
}

const CustomToolbar: React.FC<ICustomToolbarProps> = ({
  isColumnsFilterEnabled,
  isFilterEnabled,
  isDensityEnabled,
  isExportingEnabled
}): JSX.Element => {
  const theme = useTheme();

  const toolbarButtonStyles = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '5px',
    marginY: '5px',
    width: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  };

  return (
    <GridToolbarContainer>
      {isColumnsFilterEnabled && <GridToolbarColumnsButton sx={toolbarButtonStyles} />}
      {isFilterEnabled && <GridToolbarFilterButton sx={toolbarButtonStyles} />}
      {isDensityEnabled && <GridToolbarDensitySelector sx={toolbarButtonStyles} />}
      {isExportingEnabled && <GridToolbarExport sx={toolbarButtonStyles} />}
    </GridToolbarContainer>
  );
};
export default React.memo(CustomToolbar);
