import _ from 'lodash';
import { UserPermissions } from '../data/enums/permissions-enum';
import { UserRoles } from '../data/enums/roles-enum';
import { useAuth } from '../providers/authentication/AuthProvider';

interface IUseHasAuthorization {
  hasAnyRoles: (roles: UserRoles[]) => boolean;
  hasAllRoles: (roles: UserRoles[]) => boolean;
  hasAnyPermissions: (permissions: UserPermissions[]) => boolean;
  hasAllPermissions: (permissions: UserPermissions[]) => boolean;
  hasAnyPolicy: (roles: UserRoles[], permissions: UserPermissions[]) => boolean;
}

export default function useHasAuthorization(): IUseHasAuthorization {
  const { role, permissions } = useAuth();

  const hasAnyRoles = (rolesInput: UserRoles[]): boolean => {
    return _.intersection(rolesInput, [role]).length > 0;
  };
  const hasAllRoles = (rolesInput: UserRoles[]): boolean => {
    return _.intersection(rolesInput, [role]).length === rolesInput.length;
  };
  const hasAnyPermissions = (permissionsInput: UserPermissions[]): boolean => {
    return _.intersection(permissionsInput, permissions).length > 0;
  };
  const hasAllPermissions = (permissionsInput: UserPermissions[]): boolean => {
    return _.intersection(permissionsInput, permissions).length === permissionsInput.length;
  };

  const hasAnyPolicy = (rolesInput: UserRoles[] = [], permissionsInput: UserPermissions[] = []): boolean => {
    if (_.isEmpty(rolesInput) && _.isEmpty(permissionsInput)) {
      return true;
    } else if (!_.isEmpty(permissionsInput)) {
      return hasAnyPermissions(permissionsInput);
    } else {
      return hasAnyRoles(rolesInput);
    }
  };

  return { hasAnyRoles, hasAllRoles, hasAnyPermissions, hasAllPermissions, hasAnyPolicy };
}
