import { Box, Button } from '@mui/material';
import useCustomNavigation from '../../hooks/useCustomNavigation';
import { RoutesEnum } from '../../data/enums/routes-enum';
import LanguageBtn from './language-btn/LanguageBtn';
import LogoImage from './logo/LogoImage';

interface TopbarNotAuthenticatedProps {
  currentLang: string;
  languageClickHandler: (lang: string) => void;
  supportedLanguages: string[];
}

const TopBarNotAuthenticated: React.FC<TopbarNotAuthenticatedProps> = ({
  currentLang,
  languageClickHandler,
  supportedLanguages
}): JSX.Element => {
  const { navigate } = useCustomNavigation();

  const handleLoginBtnClick = (): void => {
    navigate(RoutesEnum.LOGIN);
  };
  return (
    <>
      <LogoImage />
      <Box sx={{ alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <LanguageBtn
            currentLang={currentLang}
            languageClickHandler={languageClickHandler}
            supportedLanguages={supportedLanguages}
          />
          <Box>
            <Button size="small" color="secondary" variant="contained" onClick={handleLoginBtnClick}>
              Login
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TopBarNotAuthenticated;
