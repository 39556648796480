import React, { PropsWithChildren, useContext, useState } from 'react';
import SnackBar, { Severity } from '../../../components/snack-bar/SnackBar';

interface ISnackBar {
  openSuccessSnackBar: (message: string, duration?: number | null) => void;
  openErrorSnackBar: (message: string, duration?: number | null) => void;
  openInfoSnackBar: (message: string, duration?: number | null) => void;
  openWarningSnackBar: (message: string, duration?: number | null) => void;
  closeSnackBar: () => void;
  open: boolean;
  message: string;
  severity: Severity;
  duration: number | null;
}

const SnackBarContext = React.createContext<ISnackBar>({} as unknown as ISnackBar);

const useSnackBar = (): ISnackBar => {
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [messageSnackbar, setMessageSnackbar] = useState<string>('');
  const [severitySnackbar, setSeveritySnackbar] = useState<Severity>('info');
  const [durationSnackbar, setDurationSnackbar] = useState<number | null>(null);

  const openSnackBar = (message: string, severity: Severity, duration: number | null = null): void => {
    setMessageSnackbar(message);
    setSeveritySnackbar(severity);
    setDurationSnackbar(duration);
    setOpenSnackbar(true);
  };
  const openSuccessSnackBar = (message: string, duration: number | null = null): void => {
    openSnackBar(message, 'success', duration);
  };
  const openInfoSnackBar = (message: string, duration: number | null = null): void => {
    openSnackBar(message, 'info', duration);
  };
  const openWarningSnackBar = (message: string, duration: number | null = null): void => {
    openSnackBar(message, 'warning', duration);
  };
  const openErrorSnackBar = (message: string, duration: number | null = null): void => {
    openSnackBar(message, 'error', duration);
  };
  const closeSnackBar = (): void => {
    setOpenSnackbar(false);
  };

  return {
    closeSnackBar,
    openErrorSnackBar,
    openInfoSnackBar,
    openSuccessSnackBar,
    openWarningSnackBar,
    duration: durationSnackbar,
    message: messageSnackbar,
    open: openSnackbar,
    severity: severitySnackbar
  };
};

export const SnackBarProvider: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const provider = useSnackBar();
  return (
    <SnackBarContext.Provider value={provider}>
      {children}
      <SnackBar
        autoHideDuration={provider.duration}
        closeSnackbar={provider.closeSnackBar}
        message={provider.message}
        open={provider.open}
        severity={provider.severity}
      />
    </SnackBarContext.Provider>
  );
};

export const useSnack = (): ISnackBar => useContext(SnackBarContext);
