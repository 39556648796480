import AccountCircle from '@mui/icons-material/AccountCircle';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import React from 'react';
import { Translation } from 'react-i18next';
import { MenuActionEnum } from '../../../data/enums/menu-action-enum';
import { MenuItemObj } from '../../../data/models/menu-item';

interface AccountMenuProps {
  loggedUser: string;
  actionCallBack: (action: MenuActionEnum) => void;
  accountMenuItems: MenuItemObj[];
}

const AccountMenu: React.FC<AccountMenuProps> = ({ loggedUser, actionCallBack, accountMenuItems }): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <IconButton
            onClick={handleClick}
            size="small"
            color="inherit"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <AccountCircle fontSize="small" />
            <Typography
              sx={{
                minWidth: 100,
                ml: 2,
                display: (_theme): string => {
                  if (matches) {
                    return 'none';
                  }
                  return 'flex';
                }
              }}>
              {loggedUser}
            </Typography>
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          {_.map(accountMenuItems, (item: MenuItemObj): JSX.Element => {
            return (
              <MenuItem
                key={item.id}
                onClick={(): void => {
                  actionCallBack(item.action);
                  handleClose();
                }}>
                {item.image}
                <Translation ns={item?.namespace ?? 'common'}>{(t): string => t(item.text)}</Translation>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    </React.Fragment>
  );
};

export default AccountMenu;
