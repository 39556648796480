import { Refresh } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  useTheme
} from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicDataGrid, { CustomPaginationModel } from '../../../components/data-grid/BaseDataGrid';
import { Intent } from '../../../shared/data/dto/intent-dto';
import { AgentLanguage } from '../../../shared/data/enums/agent-language.enum';
import { FulfillmentEnum } from '../../../shared/data/enums/fulfillment-enum';
import { RoutesEnum } from '../../../shared/data/enums/routes-enum';
import useAxiosFulfillment from '../../../shared/hooks/api/useAxiosFulfillment';
import useCustomNavigation from '../../../shared/hooks/useCustomNavigation';
import { useSnack } from '../../../shared/providers/utils/SnackBarProvider';

const TemplateResponse: React.FC = (): JSX.Element => {
  const { getAllIntents, getStatus, getEditStatus } = useAxiosFulfillment();
  const [intents, setIntents] = useState<Intent[]>([]);
  const [dirty, setDirty] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(8);
  const [offset, setOffset] = useState<number>(0);
  const { navigateToWithParams } = useCustomNavigation();
  const [nRows, setNRows] = useState<number>(0);
  const [originalElements, setOriginalElements] = useState<Intent[]>([]);
  const { t } = useTranslation(['fulfillment']);
  const [language, setLanguage] = useState<string>(AgentLanguage.ITALIAN);
  const theme = useTheme();
  const { openErrorSnackBar } = useSnack();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  useEffect((): void => {
    getEditStatus().then((res): void => setIsEdit(res));
    getAllIntents(language)
      .then((res: Intent[]): void => {
        setNRows(res.length);
        const list = res.map((x: Intent): Intent => {
          return { id: crypto.randomUUID(), intent: x.intent, status: x.status };
        });
        setIntents(list);
        setOriginalElements(list);
      })
      .catch((): void => {
        openErrorSnackBar(t('snack_messagge.error_load'));
        setIntents([]);
      })
      .finally((): void => setDirty(true));
  }, [language]);

  const refreshStatus = (refreshIntent: string): void => {
    getStatus(refreshIntent, language).then((newStatus): void => {
      const newIntents = intents.map(
        (x: Intent): Intent => (x.intent === refreshIntent ? { id: x.id, intent: x.intent, status: newStatus } : x)
      );
      setIntents(newIntents);
      setOriginalElements(newIntents);
      getEditStatus().then((res): void => setIsEdit(res));
    });
  };

  const onChangePaginationModel = (model: CustomPaginationModel): void => {
    setOffset(model.offset);
    setPage(model.page);
    setPageSize(model.pageSize);
  };

  const onChangeFreeSearch = (search: string): void => {
    if (_.isEmpty(search)) {
      setIntents(originalElements);
    } else {
      const filteredList: Intent[] = originalElements.filter(
        (el: Intent): boolean => el.intent.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
      setIntents(filteredList);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'intent',
      headerName: t('intent.name').toString(),
      width: 950
    },
    {
      field: 'status',
      headerName: t('intent.status').toString(),
      width: 100,
      renderCell: (params): JSX.Element => (
        <>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid>
              {params.row.status === FulfillmentEnum.OK ? <CheckCircleIcon htmlColor={'green'} /> : <></>}
              {params.row.status === FulfillmentEnum.ERROR ? <ErrorIcon htmlColor={'red'} /> : <></>}
              {params.row.status === FulfillmentEnum.LOADING ? (
                <Box>
                  <CircularProgress size={'20px'} />
                </Box>
              ) : (
                <></>
              )}
            </Grid>
            {params.row.status === 'Loading' ? (
              <Grid sx={{ marginTop: '-8px' }}>
                <Box>
                  <GridActionsCellItem
                    icon={<Refresh viewBox="0 0 20 20" />}
                    label="Save"
                    sx={{
                      color: 'primary.main'
                    }}
                    onClick={(): void => refreshStatus(params.row.intent)}
                  />
                </Box>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </>
      )
    },
    {
      field: 'edit',
      headerName: '',
      width: 100,
      renderCell: ({ row }): JSX.Element => (
        <>
          <Grid container>
            <Grid sx={{ marginBottom: '15px' }}>
              <GridActionsCellItem
                icon={<EditIcon htmlColor={isEdit ? theme.palette.grey[400] : theme.palette.primary.main} />}
                disabled={isEdit}
                label="Save"
                onClick={(): void => {
                  navigateToWithParams(RoutesEnum.FULFILLMENT_UPDATE, [
                    { key: ':language', value: language },
                    { key: ':intent', value: row.intent }
                  ]);
                }}
              />
            </Grid>
          </Grid>
        </>
      )
    }
  ];

  return (
    <>
      <Container>
        <Box sx={{ width: '100%' }}>
          {dirty ? (
            <>
              <Box sx={{ marginBottom: '40px' }}>
                <FormControl fullWidth>
                  <InputLabel id="select-language">{t('language.label').toString()}</InputLabel>
                  <Select
                    labelId="select-language"
                    id="select-language"
                    value={language}
                    label={t('language.label').toString()}
                    onChange={(event: SelectChangeEvent): void => {
                      setLanguage(event.target.value);
                      setDirty(false);
                    }}>
                    {Object.values(AgentLanguage).map(
                      (x): JSX.Element => (
                        <MenuItem key={x} value={x}>
                          {t(`language.value.${x}`).toString()}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Box>
              <BasicDataGrid
                enableFreeSearch={true}
                onChangeFreeSearch={onChangeFreeSearch}
                rowCount={nRows}
                getRowId={(row): string => row.id}
                rows={intents}
                columns={columns}
                paginationMode="client"
                isLoading={false}
                pageSizeOptions={[8, 16, 24]}
                paginationModel={{ pageSize, page }}
                onChangePagination={onChangePaginationModel}
                page={page}
                offset={offset}
              />
            </>
          ) : (
            <Stack spacing={1}>
              {Array.of(1, 2, 3, 4, 5).map(
                (x): JSX.Element => (
                  <Skeleton key={x} variant="rounded" height={60} />
                )
              )}
            </Stack>
          )}
        </Box>
      </Container>
    </>
  );
};

export default TemplateResponse;
