import _ from 'lodash';
import { FreeTextSearch, IBodyRequest, IConditions, IPageRequest, SortingRequest } from '../models/search-models';

export const buildTableFiltarablePageableRequest = (
  offset: number,
  limit: number,
  conditions: IConditions[],
  sorting?: SortingRequest[],
  freeSearch?: FreeTextSearch
): IBodyRequest => {
  const pageRequest: IPageRequest = {
    limit,
    offset
  };

  const sortingRequest = _.isNil(sorting) ? undefined : { conditions: sorting };
  const freeTextSearch = _.isNil(freeSearch) ? undefined : freeSearch;

  return !_.isEmpty(conditions)
    ? {
        queryRequest: {
          conditions
        },
        pageRequest,
        sortingRequest,
        freeTextSearch
      }
    : { pageRequest, sortingRequest, freeTextSearch };
};
