import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationState, initialAuthState } from '../models/authentication-state';

export const authenticationSlice = createSlice({
  name: 'authentication',
  reducers: {
    loggedIn: (state, action: PayloadAction<AuthenticationState>): void => {
      state.firebaseUser = action.payload.firebaseUser;
    },
    loggedOut: (state): void => {
      state.firebaseUser = null;
    }
  },
  initialState: initialAuthState
});
export default authenticationSlice.reducer;
