import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IDialogProps {
  show: boolean;
  handleLogout: () => void;
}

const SessionExpiredDialog: React.FC<IDialogProps> = ({ show, handleLogout }): JSX.Element => {
  const { t } = useTranslation(['common']);

  return (
    <Dialog open={show} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t('errors.session.expired_title')}</DialogTitle>
      <DialogContent id="alert-dialog-description">{t('errors.session.expired_text')}</DialogContent>
      <DialogActions>
        <Button onClick={handleLogout}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionExpiredDialog;
