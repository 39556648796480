import React, { PropsWithChildren, useEffect } from 'react';
import { RoutesEnum } from '../../data/enums/routes-enum';
import useCustomNavigation from '../../hooks/useCustomNavigation';
import { useAuth } from '../authentication/AuthProvider';

const PublicRoute: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const { navigateConditional } = useCustomNavigation();
  const { isAuthenticated } = useAuth();

  useEffect((): void => {
    if (isAuthenticated()) {
      navigateConditional(RoutesEnum.LANDING_PAGE, RoutesEnum.DASHBOARD, RoutesEnum.DASHBOARD, true);
    }
  }, []);

  return <>{children}</>;
};

export default PublicRoute;
