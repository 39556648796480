import { Check } from '@mui/icons-material';
import LanguageIcon from '@mui/icons-material/Language';
import { Box, Grid, IconButton, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';

interface LanguageBtnProps {
  currentLang: string;
  languageClickHandler: (lang: string) => void;
  supportedLanguages: string[];
}

const LanguageBtn: React.FC<LanguageBtnProps> = ({
  currentLang,
  languageClickHandler,
  supportedLanguages
}): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [languageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [language, setLanguage] = React.useState<string>(currentLang);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    setLanguageMenuOpen(true);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setLanguageMenuOpen(false);
  };

  const languageBtnClickHandler = (lang: string): void => {
    languageClickHandler(lang);
  };

  const handleClickLanguage = (lang: string): void => {
    setLanguage(lang);
    languageBtnClickHandler(lang);
    handleClose();
  };
  return (
    <Box>
      <IconButton
        size="small"
        edge="start"
        color="inherit"
        aria-label="menu-language"
        id="language-btn"
        aria-controls={languageMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={languageMenuOpen ? 'true' : undefined}
        sx={{ mr: 5 }}
        onClick={handleClick}>
        <LanguageIcon fontSize="small" />
        <Typography
          fontSize="small"
          sx={{
            ml: 3,
            display: (_theme): string => {
              if (matches) {
                return 'none';
              }
              return 'flex';
            }
          }}>
          {_.toUpper(language)}
        </Typography>
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={languageMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-btn'
        }}>
        {_.map(supportedLanguages, (item: string): JSX.Element => {
          return (
            <MenuItem key={item} onClick={(): void => handleClickLanguage(item)}>
              <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    justifyContent: 'start',
                    paddingRight: '5px'
                  }}>
                  {_.isEqual(language, item) ? (
                    <Check
                      sx={{
                        fontSize: '15px',
                        verticalAlign: 'middle'
                      }}
                    />
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid
                  xs={6}
                  item
                  sx={{
                    justifyContent: 'end',
                    paddingLeft: '5px'
                  }}>
                  <span>{item}</span>
                </Grid>
              </Grid>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default LanguageBtn;
