import { Box, Button, Container, Typography } from '@mui/material';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomNavigation from '../../../shared/hooks/useCustomNavigation';

const ErrorPage: FC = (): JSX.Element => {
  const { t } = useTranslation(['common']);
  const { navigateBack } = useCustomNavigation();

  return (
    <Box>
      <Container maxWidth="md">
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            pt: 10
          }}>
          <Typography variant="h1" color="primary" sx={{ pb: 3 }}>
            Ooops!
          </Typography>
          <Typography variant="h4" color="primary">
            404 - {t('errors.error_page.subtitle')}
          </Typography>
          <Typography variant="body1" color="primary" sx={{ py: 10 }}>
            {t('errors.error_page.explain')}
          </Typography>
          <Button size="large" variant="contained" color="secondary" onClick={(): void => navigateBack()}>
            {t('errors.error_page.go_back')}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default memo(ErrorPage);
