import { ResFulfillDTO } from '../dto/fulfillment-response.dto';
import { ResFulfill } from '../models/fulfillment-response.model';

export function fromResFulfillDTOtoResFulfillModel(DTO: ResFulfillDTO): ResFulfill {
  const responseModel: ResFulfill = {
    param: DTO.param,
    link: DTO.link,
    text: DTO.text
  };
  return responseModel;
}
