import React, { PropsWithChildren, useContext, useState } from 'react';
import SessionExpiredDialog from '../../../components/logout-dialog/SessionExpiredDialog';
import { RoutesEnum } from '../../data/enums/routes-enum';
import { useAuth } from '../authentication/AuthProvider';

interface IDialogProps {
  showDialog: () => void;
  show: boolean;
  handleLogout: () => void;
}

const SessionExpiredDialogContext = React.createContext<IDialogProps>({} as unknown as IDialogProps);

const useExpiredDialog = (): IDialogProps => {
  const [show, setShow] = useState<boolean>(false);
  const { logout } = useAuth();

  const showDialog = (): void => {
    setShow(true);
  };

  const handleLogout = (): void => {
    setShow(false);
    logout().then((_): void => {
      window.location.replace(`${RoutesEnum.LOGIN}` ?? '');
    });
  };

  return {
    showDialog,
    show,
    handleLogout
  };
};

export const SessionExpiredProvider: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const provider = useExpiredDialog();

  return (
    <SessionExpiredDialogContext.Provider value={provider}>
      {children}
      <SessionExpiredDialog show={provider.show} handleLogout={provider.handleLogout} />
    </SessionExpiredDialogContext.Provider>
  );
};

export const useSessionExpiredDialog = (): IDialogProps => useContext(SessionExpiredDialogContext);
