import { SessionStorageKey } from '../data/enums/session-storage-key';
import { changeDrawerState } from '../store/actions/application-actions';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { currentDrawerState } from '../store/selectors/application-selector';

export interface IUseDrawer {
  open: boolean;
  toggleDrawer: () => void;
}

export default function useDrawer(): IUseDrawer {
  const drawerState = useAppSelector(currentDrawerState);
  const dispatch = useAppDispatch();

  const toggleDrawer = (): void => {
    const toggle = !drawerState;
    sessionStorage.setItem(SessionStorageKey.DRAWER_STATE, `${toggle}`);
    dispatch(changeDrawerState(toggle));
  };

  return {
    open: drawerState,
    toggleDrawer
  };
}
