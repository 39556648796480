import axios, { Axios, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { SessionStorageKey } from '../../data/enums/session-storage-key';
import { useAuth } from '../../providers/authentication/AuthProvider';
import { useLoading } from '../../providers/utils/LoadingSpinnerProvider';
import { useSessionExpiredDialog } from '../../providers/utils/SessionExpiredProvider';

interface IUseCustomAxios {
  axios: Axios;
}

export default function useCustomAxios(): IUseCustomAxios {
  const axiosInstance = axios.create();
  const { user } = useAuth();
  const { showLoader, hideLoader } = useLoading();
  const { showDialog } = useSessionExpiredDialog();

  axiosInstance.interceptors.request.use(
    // eslint-disable-next-line
    (config): InternalAxiosRequestConfig<any> => {
      showLoader();
      const token = window.atob(sessionStorage.getItem(SessionStorageKey.TOKEN) as string);
      config.headers.Authorization = `Bearer ${user?.token ? user.token : token}`;
      return config;
    },
    (error): void => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    // eslint-disable-next-line
    (value): AxiosResponse<any, any> => {
      hideLoader();
      return value;
    },
    (error): Promise<void> => {
      hideLoader();
      if (error.response.status === 401) {
        showDialog();
      }
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );

  return {
    axios: axiosInstance
  };
}
