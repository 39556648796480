import React, { PropsWithChildren, SetStateAction, useState } from 'react';
import { MenuActionEnum } from '../../data/enums/menu-action-enum';
import { MenuItemObj } from '../../data/models/menu-item';
import { IRouteParams } from '../../hooks/useCustomNavigation';
import useDrawer from '../../hooks/useDrawer';
import { useAuth } from '../../providers/authentication/AuthProvider';
import MiniDrawer from '../drawer/MiniDrawer';
import Footer from '../footer/Footer';
import TopBar from '../top-bar/TopBar';

interface BaseLayoutProps {
  actionCallBack: (action: MenuActionEnum, params?: IRouteParams[]) => void;
  loggedUser: string;
  currentLang: string;
  languageClickHandler: (lang: string) => void;
  appVersion: string;
  logo: string;
  drawerItems: MenuItemObj[];
  accountMenuItems: MenuItemObj[];
  supportedLanguages: string[];
}

const BaseLayout: React.FC<BaseLayoutProps & PropsWithChildren> = ({
  actionCallBack,
  currentLang,
  languageClickHandler,
  loggedUser,
  logo,
  appVersion,
  children,
  drawerItems,
  accountMenuItems,
  supportedLanguages
}): JSX.Element => {
  const { open, toggleDrawer } = useDrawer();
  const { isAuthenticated } = useAuth();
  const [isDirtyDrawer, setIsDirtyDrawer] = useState<boolean>(false);

  return (
    <>
      <TopBar
        hamburgerIconClickHandler={toggleDrawer}
        currentLang={currentLang}
        languageClickHandler={languageClickHandler}
        loggedUser={loggedUser}
        actionCallBack={actionCallBack}
        supportedLanguages={supportedLanguages}
        accountMenuItems={accountMenuItems}
      />
      {isAuthenticated() ? (
        <MiniDrawer
          currentLang={currentLang}
          loggedUser={loggedUser}
          drawerItems={drawerItems}
          actionCallBack={actionCallBack}
          open={open}
          setDirtyDrawer={(dirty: SetStateAction<boolean>): void => setIsDirtyDrawer(dirty)}
          isDirty={isDirtyDrawer}>
          {children}
        </MiniDrawer>
      ) : (
        children
      )}
      <Footer logo={logo} version={appVersion}></Footer>
    </>
  );
};

export default BaseLayout;
