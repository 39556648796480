import { AppBar, Box, Toolbar } from '@mui/material';
import React from 'react';
import { MenuActionEnum } from '../../data/enums/menu-action-enum';
import { MenuItemObj } from '../../data/models/menu-item';
import { useAuth } from '../../providers/authentication/AuthProvider';
import TopBarAuthenticated from './TopBarAuthenticated';
import TopBarNotAuthenticated from './TopBarNotAuthenticated';

interface TopBarProps {
  actionCallBack: (action: MenuActionEnum) => void;
  loggedUser: string;
  currentLang: string;
  languageClickHandler: (lang: string) => void;
  hamburgerIconClickHandler: () => void;
  supportedLanguages: string[];
  accountMenuItems: MenuItemObj[];
}

const TopBar: React.FC<TopBarProps> = ({
  actionCallBack,
  loggedUser,
  currentLang,
  languageClickHandler,
  hamburgerIconClickHandler,
  supportedLanguages,
  accountMenuItems
}): JSX.Element => {
  const { isAuthenticated } = useAuth();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="app-bar" sx={{ position: 'fixed', height: '60px' }}>
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          {isAuthenticated() ? (
            <TopBarAuthenticated
              accountMenuItems={accountMenuItems}
              actionCallBack={actionCallBack}
              currentLang={currentLang}
              hamburgerIconClickHandler={hamburgerIconClickHandler}
              languageClickHandler={languageClickHandler}
              loggedUser={loggedUser}
              supportedLanguages={supportedLanguages}
            />
          ) : (
            <TopBarNotAuthenticated
              currentLang={currentLang}
              languageClickHandler={languageClickHandler}
              supportedLanguages={supportedLanguages}
            />
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopBar;
