import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { routes } from './Routes';

const App = (): JSX.Element => {
  const elements = useRoutes(routes);
  return (
    <>
      <CssBaseline />
      {elements}
    </>
  );
};

export default App;
