import Menu from '@mui/icons-material/Menu';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';

interface HamburgerMenuIconProps {
  hamburgerIconClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const HamburgerMenuIcon: React.FC<HamburgerMenuIconProps> = ({ hamburgerIconClick }): JSX.Element => {
  return (
    <Box>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={hamburgerIconClick}>
        <Menu />
      </IconButton>
    </Box>
  );
};

export default HamburgerMenuIcon;
