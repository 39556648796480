import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { SessionStorageKey } from '../../data/enums/session-storage-key';
import { SupportedLanguageEnum } from '../../data/enums/supported-language-enum';
import useUtils from '../../hooks/useUtils';
import i18n from '../../i18n/i18n';
import { changelanguage } from '../../store/actions/application-actions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectedLanguage } from '../../store/selectors/application-selector';

interface ILanguageProvider {
  lang: string;
  changeLanguage: (lang: string) => void;
  supportedLanguages: string[];
}

const LanguageContext = React.createContext<ILanguageProvider>({} as unknown as ILanguageProvider);

const useLanguageProvider = (): ILanguageProvider => {
  const language = useAppSelector(selectedLanguage);
  const dispatch = useAppDispatch();
  const { enumPossibleValues } = useUtils();
  const supportedLanguages = enumPossibleValues(SupportedLanguageEnum) as unknown as string[];

  const changeLanguage = (lang: string): void => {
    i18n
      .changeLanguage(lang)
      .then((_res): void => {
        dispatch(changelanguage(lang));
        sessionStorage.setItem(SessionStorageKey.LANGUAGE_STATE, lang);
      })
      .catch((err): void => {
        throw new Error((err as Error).message);
      });
  };

  useEffect((): void => {
    changeLanguage(language);
  }, []);

  return {
    lang: language,
    changeLanguage,
    supportedLanguages
  };
};

export const LanguageProvider: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const provider = useLanguageProvider();
  return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>;
};

export const useLang = (): ILanguageProvider => useContext(LanguageContext);
