import { Alert, Snackbar, Stack } from '@mui/material';
import React from 'react';
export type Severity = 'success' | 'error' | 'info' | 'warning';

interface ISnackbarProps {
  open: boolean;
  autoHideDuration: number | null;
  closeSnackbar: () => void;
  message: string;
  severity: Severity;
}

const SnackBar: React.FC<ISnackbarProps> = ({
  open,
  autoHideDuration = null,
  closeSnackbar,
  severity,
  message
}): JSX.Element => {
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={closeSnackbar}
        sx={{ alignItems: 'center' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={closeSnackbar} severity={severity} sx={{ width: '100%', alignItems: 'center' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default React.memo(SnackBar);
