import { Box } from '@mui/material';
import logo from '../../../../assets/cloudbot-logo.svg';
const LogoImage: React.FC = (): JSX.Element => {
  return (
    <Box sx={{ '& > img': { verticalAlign: 'middle' } }}>
      <img src={logo} alt="logo" />
    </Box>
  );
};

export default LogoImage;
