import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  EnhancedStore,
  MiddlewareArray,
  PreloadedState,
  StoreEnhancer,
  ThunkAction,
  ThunkMiddleware
} from '@reduxjs/toolkit';
import { IApplicationState } from './models/application-state';
import { AuthenticationState } from './models/authentication-state';
import applicationReducer from './reducers/application-reducer';
import authenticationReducer from './reducers/authentication-reducer';

export type IStore = {
  authentication: AuthenticationState;
  application: IApplicationState;
};

const combinedReducers = combineReducers({
  authentication: authenticationReducer,
  application: applicationReducer
});

export const setupStore = (
  preloadedState?: PreloadedState<IStore>
): EnhancedStore<
  IStore,
  AnyAction,
  MiddlewareArray<[ThunkMiddleware<IStore, AnyAction, undefined>]>,
  [StoreEnhancer]
> => {
  return configureStore<
    IStore,
    AnyAction,
    MiddlewareArray<[ThunkMiddleware<IStore, AnyAction, undefined>]>,
    [StoreEnhancer]
  >({
    reducer: combinedReducers,
    preloadedState
  });
};

const store = setupStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
