import { createContext, useContext } from 'react';
import IConfigProvider from '../../data/models/confg-provider';
import { useConfigProvider } from './useConfigProvider';

const ConfigContext = createContext<IConfigProvider>({
  env: 'none',
  debug: true,
  baseUrl: '',
  appName: '',
  appVersion: '',
  firebase: {
    projectId: '',
    appId: '',
    storageBucket: '',
    apiKey: '',
    authDomain: '',
    messagingSenderId: '',
    baseUrl: ''
  },
  analyticsUrl: '',
  ingestionUrl: '',
  ingestionXlsxUrl: '',
  cccUnipaUrl: '',
  cccProxyUnipaUrl: '',
  urlTemplateXlsx: ''
});

export const useConfig = (): IConfigProvider => {
  return useContext(ConfigContext);
};

export function ConfigProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const config = useConfigProvider();
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}
