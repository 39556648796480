import React, { PropsWithChildren, useEffect } from 'react';
import { RoutesEnum } from '../../data/enums/routes-enum';
import useCustomNavigation from '../../hooks/useCustomNavigation';
import { useAuth } from '../authentication/AuthProvider';

const PrivateRoute: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const { navigate } = useCustomNavigation();
  const { isAuthenticated } = useAuth();

  useEffect((): void => {
    if (!isAuthenticated()) {
      navigate(RoutesEnum.LOGIN, true);
    }
  }, []);

  return <>{children}</>;
};

export default PrivateRoute;
