import React, { PropsWithChildren, useContext, useState } from 'react';
import LoadingSpinner from '../../../components/loading-spinner/LoadingSpinner';

interface ILoading {
  isLoading: boolean;
  showLoader: () => void;
  hideLoader: () => void;
}

const LoadingSpinnerContext = React.createContext<ILoading>({} as unknown as ILoading);

const useLoadingSpinner = (): ILoading => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const showLoader = (): void => {
    setIsLoading(true);
  };
  const hideLoader = (): void => {
    setIsLoading(false);
  };

  return {
    isLoading,
    showLoader,
    hideLoader
  };
};

export const LoadingProvider: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const provider = useLoadingSpinner();
  return (
    <LoadingSpinnerContext.Provider value={provider}>
      {provider.isLoading ? <LoadingSpinner /> : <></>}
      {children}
    </LoadingSpinnerContext.Provider>
  );
};

export const useLoading = (): ILoading => useContext(LoadingSpinnerContext);
