import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialAppState } from '../models/application-state';

export const applicationSlice = createSlice({
  name: 'application',
  reducers: {
    changeDrawerState: (state, action: PayloadAction<boolean>): void => {
      state.drawerOpen = action.payload;
    },
    changelanguage: (state, action: PayloadAction<string>): void => {
      state.currentLanguage = action.payload;
    }
  },
  initialState: initialAppState
});
export default applicationSlice.reducer;
