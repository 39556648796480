import { IGroup, IGroupDTO, IGroupFirebase, IGroupList } from '../../data/dto/group-dto';
import { buildTableFiltarablePageableRequest } from '../../data/mappers/build-page-request';
import { fromIGroupToIGroupFirebase } from '../../data/mappers/group-mappers';
import { FreeTextSearch, IConditions, SortingRequest } from '../../data/models/search-models';
import { useConfigProvider } from '../../providers/config/useConfigProvider';
import useCustomAxios from '../interceptors/useCustomAxios';

interface IBodyDeleteGroup {
  ids: string[];
}

interface IAxiosGroups {
  getList(
    offset: number,
    limit: number,
    conditions: IConditions[],
    sorting?: SortingRequest[],
    freeSearch?: FreeTextSearch
  ): Promise<IGroupList>;
  getGroupById(id: string): Promise<IGroupFirebase>;
  deleteGroupById(id: string[]): Promise<number>;
  addGroup(bodyGroup: IGroup): Promise<IGroupDTO>;
  updateGroup(bodyGroup: IGroup): Promise<IGroupDTO>;
  activateGroupById(id: string[]): Promise<number>;
}

export default function useAxiosGroups(): IAxiosGroups {
  const { firebase } = useConfigProvider();
  const { axios } = useCustomAxios();
  const groupUrl = `${firebase.baseUrl}/groups`;
  /**
   *
   * @param offset - index of element
   * @param limit - number of element to return
   * @param conditions - vector of conditions of filters
   * @returns a promise of Groups
   */
  const getList = async (
    offset: number,
    limit: number,
    conditions: IConditions[],
    sorting?: SortingRequest[],
    freeSearch?: FreeTextSearch
  ): Promise<IGroupList> => {
    const body = buildTableFiltarablePageableRequest(offset, limit, conditions, sorting, freeSearch);
    const res: IGroupList = await (await axios.post(`${groupUrl}/list`, body)).data;
    return res;
  };

  /**
   *
   * @param id - id of the group to get
   * @returns a group
   */
  const getGroupById = async (id: string): Promise<IGroupFirebase> => {
    const group = await axios.get(`${groupUrl}/${id}`);
    return group.data;
  };

  const deleteGroupById = async (id: string[]): Promise<number> => {
    const body: IBodyDeleteGroup = {
      ids: id
    };
    const response = await axios.post(`${groupUrl}/delete`, body);
    return response.data;
  };

  /**
   *
   * @param bodyGroup - DTO of new group
   * @returns data
   */
  const addGroup = async (bodyGroup: IGroup): Promise<IGroupDTO> => {
    const res = await axios.post(`${groupUrl}/create`, fromIGroupToIGroupFirebase(bodyGroup));
    return res.data;
  };

  /**
   *
   * @param bodyGroup - DTO of group to update
   * @returns data
   */
  const updateGroup = async (bodyGroup: IGroup): Promise<IGroupDTO> => {
    const updatedGroup: IGroupFirebase = fromIGroupToIGroupFirebase(bodyGroup);
    updatedGroup.id = bodyGroup.id;
    const res = await axios.post(`${groupUrl}/update`, updatedGroup);
    return res.data;
  };
  /*
   * Function to activate the group disactivated
   * @param id - Identifier of groups to activate
   */
  const activateGroupById = async (id: string[]): Promise<number> => {
    const body: IBodyDeleteGroup = {
      ids: id
    };
    const response = await axios.post(`${groupUrl}/activate`, body);
    return response.data;
  };

  return {
    getList,
    getGroupById,
    deleteGroupById,
    addGroup,
    updateGroup,
    activateGroupById
  };
}
