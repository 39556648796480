import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useConfigProvider } from '../config/useConfigProvider';

interface IFirebaseAppProvider {
  firebaseApp: FirebaseApp | null;
  auth: Auth | null;
}

const FirebaseContext = React.createContext<IFirebaseAppProvider>({
  firebaseApp: null,
  auth: null
});

const useFirebaseApp = (): IFirebaseAppProvider => {
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);
  const [auth, setAuth] = useState<Auth | null>(null);
  const { firebase } = useConfigProvider();

  useEffect((): void => {
    const app = initializeApp({
      apiKey: firebase.apiKey,
      authDomain: firebase.authDomain,
      projectId: firebase.projectId,
      storageBucket: firebase.storageBucket,
      messagingSenderId: firebase.messagingSenderId,
      appId: firebase.appId
    });
    setFirebaseApp(app);
    setAuth(getAuth(app));
  }, []);

  return {
    firebaseApp,
    auth
  };
};

export const FirebaseProvider: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const provider = useFirebaseApp();
  return <FirebaseContext.Provider value={provider}>{children}</FirebaseContext.Provider>;
};

export const useFirebase = (): IFirebaseAppProvider => useContext(FirebaseContext);
