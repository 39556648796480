import { CSSObject, styled, useTheme } from '@mui/material';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';

const StyledDataGrid = styled(DataGrid)<DataGridProps>((): CSSObject => {
  const theme = useTheme();
  return {
    border: 0,
    '& .MuiDataGrid-panel': {
      color: theme.palette.error
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.common.black,
      '& .MuiCheckbox-root': {
        color: theme.palette.common.white
      },
      '&  .MuiDataGrid-iconButtonContainer .MuiButtonBase-root .MuiSvgIcon-root': {
        color: theme.palette.common.white
      }
    },
    '& .MuiDataGrid-cell': {
      borderRight: `1px solid ${theme.palette.grey['200']}`
    },
    '& .MuiDataGrid-row': {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.grey['200']}`,
      borderBottom: 0,
      '& .MuiDataGrid-cell': {
        backgroundColor: theme.palette.background.default
      }
    },
    '& .MuiDataGrid-footerContainer': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.common.black,
      border: `1px solid ${theme.palette.grey['200']}`,
      boxShadow: `0px 1px 2px ${theme.palette.grey['100']}`
    },
    '& .MuiTablePagination-toolbar': {
      color: theme.palette.common.black
    },
    '.MuiDataGrid-menu': {
      ' .MuiSvgIcon-root': {
        color: theme.palette.common.black
      }
    },
    '.MuiDataGrid-panelWrapper': {
      backgroundColor: theme.palette.error
    },
    '& .MuiTablePagination-actions': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.common.black
      }
    },
    '& .MuiDataGrid-cellCheckbox': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.common.black
      }
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: theme.palette.secondary.main
    }
  };
}) as typeof DataGrid;

export default StyledDataGrid;
