import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

interface IUseCustomNavigation {
  navigate: (to: string, replace?: boolean, from?: string) => void;
  navigateConditional: (
    checkFromRoute: string,
    toRouteInTrueCase: string,
    toRouteInFalseCase: string,
    replace?: boolean
  ) => void;
  navigateBack: () => void;
  navigateToWithParams: (to: string, params: IRouteParams[], replace?: boolean, from?: string) => void;
}

export interface IRouteParams {
  key: string;
  value: string;
}

export default function useCustomNavigation(): IUseCustomNavigation {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = (to: string, replace = false, from?: string): void => {
    const fromLocation = location.state?.from?.pathname ?? from;
    navigate(to, { state: fromLocation, replace });
  };

  const navigateToWithParams = (to: string, params: IRouteParams[], replace = false, from?: string): void => {
    let toRoute = to;
    _.forEach(params, (p): void => {
      toRoute = _.replace(toRoute, p.key, p.value);
    });
    navigateTo(toRoute, replace, from);
  };

  const navigateConditional = (
    checkFromRoute: string,
    toRouteInTrueCase: string,
    toRouteInFalseCase: string,
    replace = false
  ): void => {
    const to =
      location.state?.from?.pathname !== checkFromRoute
        ? location.state?.from?.pathname ?? toRouteInTrueCase
        : toRouteInFalseCase;
    navigateTo(to, replace);
  };

  const navigateBack = (): void => {
    navigate(-1);
  };

  return {
    navigate: navigateTo,
    navigateConditional,
    navigateToWithParams,
    navigateBack
  };
}
