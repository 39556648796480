import _ from 'lodash';
import { SessionStorageKey } from '../../data/enums/session-storage-key';
import { SupportedLanguageEnum } from '../../data/enums/supported-language-enum';

export interface IApplicationState {
  drawerOpen: boolean;
  currentLanguage: string;
}

export const initialAppState: IApplicationState = {
  drawerOpen: _.isEqual(sessionStorage.getItem(SessionStorageKey.DRAWER_STATE) ?? 'true', 'true'),
  currentLanguage: sessionStorage.getItem(SessionStorageKey.LANGUAGE_STATE) ?? SupportedLanguageEnum.IT
};
