import { ResFulfillDTO } from '../../data/dto/fulfillment-response.dto';
import { Intent } from '../../data/dto/intent-dto';
import { fromResFulfillDTOtoResFulfillModel } from '../../data/mappers/responseFulfillment.mapper';
import { ResFulfill } from '../../data/models/fulfillment-response.model';
import { useConfigProvider } from '../../providers/config/useConfigProvider';
import useCustomAxios from '../interceptors/useCustomAxios';

interface IAxiosFulfillment {
  getAllIntents: (language: string) => Promise<Intent[]>;
  getAllResponses: (intent: string, language: string) => Promise<ResFulfill[]>;
  getStatus: (intent: string, language: string) => Promise<string>;
  editResponse: (newResponse: ResFulfill, intent: string, language: string) => Promise<ResFulfill>;
  editTemplate: (intent: string, env: string, language: string) => Promise<string>;
  setStatus: (intent: string, status: string, language: string) => Promise<string>;
  getEditStatus: () => Promise<boolean>;
}

export default function useAxiosFulfillment(): IAxiosFulfillment {
  const { axios } = useCustomAxios();
  const { cccUnipaUrl, cccProxyUnipaUrl } = useConfigProvider();

  /**
   *
   * @returns Array of Intents
   */
  const getAllIntents = async (language: string): Promise<Intent[]> => {
    const res = await axios.get(cccProxyUnipaUrl, {
      headers: {
        'Target-URL': `${cccUnipaUrl}?action=intentList&lang=${language}`
      }
    });

    if (res.data.data.message !== undefined) {
      throw Error();
    }

    return res.data.data;
  };

  /**
   *
   * @param intent - used as parameter to get status of intent
   * @returns a promise of status
   */
  const getStatus = async (intent: string, language: string): Promise<string> => {
    const res = await axios.get(cccProxyUnipaUrl, {
      headers: {
        'Target-URL': `${cccUnipaUrl}?intent=${intent}&action=status&lang=${language}`
      }
    });

    if (res.data.data.message !== undefined) {
      throw Error();
    }

    return res.data.data.status;
  };

  /**
   *
   * @param intent - param used to get all responses of an intent
   * @returns array of responses
   */
  const getAllResponses = async (intent: string, language: string): Promise<ResFulfill[]> => {
    const res = await axios.get(cccProxyUnipaUrl, {
      headers: {
        'Target-URL': `${cccUnipaUrl}?action=responses&intent=${intent}&lang=${language}`
      }
    });

    if (res.data.data.message !== undefined) {
      throw Error();
    }

    return res.data.data.data.map((x: ResFulfillDTO): ResFulfill => fromResFulfillDTOtoResFulfillModel(x));
  };

  /**
   *
   * @param newResponse - response to update
   * @returns
   */
  const editResponse = async (
    newResponse: ResFulfill,
    intentToUpdate: string,
    language: string
  ): Promise<ResFulfill> => {
    const responseToSend: ResFulfillDTO = {
      intent: intentToUpdate,
      param: newResponse.param,
      text: newResponse.text,
      link: newResponse.link
    };
    const res = await axios.post(cccProxyUnipaUrl, responseToSend, {
      headers: {
        'Target-URL': `${cccUnipaUrl}?action=setResponse&lang=${language}`
      }
    });

    if (res.data.data.message !== undefined) {
      throw Error();
    }

    return res.data.data;
  };

  /**
   *
   * @param intent - intent to update.
   * Get all response of template and create new JSON to send to fulfillment
   *
   * @returns
   */
  const editTemplate = async (intentToUpdate: string, envToUpdate: string, language: string): Promise<string> => {
    // create JSON
    const res = await axios.post(
      cccProxyUnipaUrl,
      { intent: intentToUpdate, env: envToUpdate },
      {
        headers: {
          'Target-URL': `${cccUnipaUrl}?action=createJSON&lang=${language}`
        }
      }
    );
    return res.data;
  };

  const setStatus = async (intent: string, status: string): Promise<string> => {
    const res = await axios.post(
      cccProxyUnipaUrl,
      { intent, status },
      {
        headers: {
          'Target-URL': `${cccUnipaUrl}?action=setStatus`
        }
      }
    );

    if (res.data.data.message !== undefined) {
      throw Error();
    }

    return res.data.status;
  };

  const getEditStatus = async (): Promise<boolean> => {
    const res = await axios.get(cccProxyUnipaUrl, {
      headers: {
        'Target-URL': `${cccUnipaUrl}?action=loading`
      }
    });

    return res.data.data.status;
  };

  return {
    getAllIntents,
    getAllResponses,
    editResponse,
    editTemplate,
    setStatus,
    getStatus,
    getEditStatus
  };
}
