import { Box, CircularProgress, useTheme } from '@mui/material';
import React from 'react';
interface LoadingSpinnerProps {
  bgColor?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ bgColor }): JSX.Element => {
  const { palette } = useTheme();
  return (
    <React.Fragment>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          position: 'absolute',
          height: '100%',
          width: '100%',
          zIndex: 100264,
          backgroundColor: bgColor ?? palette.grey['500']
        }}>
        <CircularProgress />
      </Box>
    </React.Fragment>
  );
};

export default LoadingSpinner;
