import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import _ from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IFreeSearchProps {
  enableFreeSearch: boolean;
  onChangeFreeSearch: (search: string) => void;
}

const FreeSearchDataGrid: React.FC<IFreeSearchProps> = ({ enableFreeSearch, onChangeFreeSearch }): JSX.Element => {
  const { t } = useTranslation(['common']);

  const [freeSearch, setFreeSearch] = useState<string>('');

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const text = event.currentTarget.value;
    setFreeSearch(text);
    if (text.length > 2) {
      onChangeFreeSearch(text);
    }
    if (_.isEmpty(text)) {
      onEmptyText();
    }
  };

  const onEmptyText = (): void => {
    setFreeSearch('');
    onChangeFreeSearch('');
  };

  return enableFreeSearch ? (
    <Grid container xs={12} justifyContent="flex-end" mt={2} mb={1}>
      <Grid xs={12} sm={8} md={4} container justifyContent="flex-end">
        <TextField
          id="data-grid-free-search"
          label={t('common_fields.search')}
          size="small"
          value={freeSearch}
          onChange={onChangeSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {_.isEmpty(freeSearch) ? (
                  <SearchIcon />
                ) : (
                  <IconButton size="small" color="error" onClick={onEmptyText}>
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default FreeSearchDataGrid;
