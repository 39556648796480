import { Box, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  DataGridProps,
  GridPaginationModel,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridValidRowModel
} from '@mui/x-data-grid';
import React from 'react';
import CustomToolbar, { ICustomToolbarProps } from './CustomToolbar';
import FreeSearchDataGrid from './FreeSearchDataGrid';
import StyledDataGrid from './StyledDataGrid';
export interface CustomPaginationModel extends GridPaginationModel {
  offset: number;
}

type IAppDataGridProps<R extends GridValidRowModel> = DataGridProps<R> & {
  isLoading: boolean;
  toolbarProps?: ICustomToolbarProps;
  enableFreeSearch?: boolean;
  onChangeFreeSearch?: (search: string) => void | undefined;
  offset: number;
  page: number;
  onChangePagination: (model: CustomPaginationModel) => void;
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
};

const BasicDataGrid = <R extends GridValidRowModel>({
  toolbarProps,
  pageSizeOptions,
  paginationModel,
  enableFreeSearch = false,
  onChangeFreeSearch,
  onChangePagination,
  page,
  offset,
  getRowHeight,
  ...otherProps
}: IAppDataGridProps<R>): JSX.Element => {
  const theme = useTheme();
  const defaultPageSizeOptions = [5, 10, 20];

  const onChangeSearch = (search: string): void => {
    if (onChangeFreeSearch) {
      onChangeFreeSearch(search);
    }
  };

  const onChangePaginationModel = (model: GridPaginationModel): void => {
    let offsetTemp = offset;
    if (page !== model.page) {
      offsetTemp = model.page * model.pageSize;
    } else {
      if (page === model.page) {
        if (model.page === 0) {
          offsetTemp = model.page;
        } else {
          offsetTemp = offset;
        }
      }
    }
    onChangePagination({ offset: offsetTemp, pageSize: model.pageSize, page });
  };

  return (
    <Box>
      <Grid alignContent="end" alignItems="center">
        <Grid xs={12} sm={8}>
          <FreeSearchDataGrid enableFreeSearch={enableFreeSearch} onChangeFreeSearch={onChangeSearch} />
        </Grid>
      </Grid>
      <StyledDataGrid<R>
        initialState={{
          pagination: {
            paginationModel
          }
        }}
        getRowHeight={getRowHeight}
        pageSizeOptions={pageSizeOptions ?? defaultPageSizeOptions}
        disableRowSelectionOnClick
        disableColumnMenu
        autoHeight
        onPaginationModelChange={onChangePaginationModel}
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{
          panel: {
            sx: {
              '& .MuiDataGrid-panelWrapper': {
                backgroundColor: theme.palette.common.white
              },
              '& .MuiTypography-root': {
                color: theme.palette.common.black
              },
              '&:hover': {
                backgroundColor: theme.palette.secondary.main
              },
              '& .MuiSwitch-switchBase': {
                color: theme.palette.common.white,
                '&.Mui-checked': {
                  color: theme.palette.secondary.main
                }
              },
              '& .MuiButton-root': {
                backgroundColor: theme.palette.grey['600'],
                '&:hover': {
                  backgroundColor: theme.palette.secondary.main
                }
              },
              '& .MuiInputBase-input.MuiNativeSelect-select > option': {
                backgroundColor: theme.palette.common.white
              }
            }
          },
          toolbar: toolbarProps
        }}
        {...otherProps}
      />
    </Box>
  );
};

export default React.memo(BasicDataGrid);
