import _ from 'lodash';
import { IGroup, IGroupFirebase } from '../dto/group-dto';
import { UserPermissions } from '../enums/permissions-enum';
import { PermissionGroupsEnum } from '../enums/permissions-groups-enum';

function elementsWithUnderscore(elements: string[]): string[] {
  const newElements: string[] = [];
  elements.forEach((x: string, i: number): void => {
    if (i !== elements.length - 1) {
      newElements.push(x.concat('_'));
    } else {
      newElements.push(x);
    }
  });
  return newElements;
}

export function uniqPermissions(permissions: UserPermissions[]): PermissionGroupsEnum[] {
  const perms: PermissionGroupsEnum[] = Object.values(PermissionGroupsEnum);
  const uniqueUserPermissions: UserPermissions[] = _.uniq(
    _.map(permissions, (g: string): string => {
      const elements: string[] = _.split(g, '_');
      elements.pop();
      let value = '';
      if (elements.length < 2) {
        _.map(elements, (x: string): string => (value = value.concat(x)));
        return value;
      } else {
        const newElements: string[] = elementsWithUnderscore(elements);
        _.map(newElements, (x: string): string => (value = value.concat(x)));
        return value;
      }
    })
  ) as UserPermissions[];

  const res: PermissionGroupsEnum[] = [];
  perms.forEach((p: PermissionGroupsEnum): void => {
    uniqueUserPermissions.forEach((u: UserPermissions): void => {
      if (u.split('_').includes(p.split('_')[0] || ' ')) {
        res.push(p);
      }
    });
  });
  return res;
}

function getLabel(permit: string): string {
  const elements: string[] = _.split(permit, '_');
  elements.pop();
  let value = '';
  if (elements.length < 2) {
    _.map(elements, (x: string): string => (value = value.concat(x)));
    return value;
  } else {
    const newElements: string[] = elementsWithUnderscore(elements);
    _.map(newElements, (x: string): string => (value = value.concat(x)));
    return value;
  }
}

export const mapGroupPermissionsToUserPermissions = (permissions: PermissionGroupsEnum[]): UserPermissions[] => {
  return _.map(permissions, (x: string): string => _.toUpper(getLabel(x)).concat('_EDITOR')).concat(
    _.map(permissions, (x: string): string => _.toUpper(getLabel(x)).concat('_VIEWER'))
  ) as UserPermissions[];
};

export const fromIGroupToIGroupFirebase = (group: IGroup): IGroupFirebase => {
  const groupMapped: IGroupFirebase = {
    permissions: mapGroupPermissionsToUserPermissions(group.permissions),
    email: group.email,
    name: _.toLower(group.name),
    businessName: group.businessName,
    address: group.address,
    vatCode: group.vatCode,
    phoneNumber: group.phoneNumber,
    firstname: group.firstname,
    lastname: group.lastname,
    limitMaxRequests: +group.limitMaxRequests,
    settings: group.settings
  };
  return groupMapped;
};
