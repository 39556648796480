import { ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.scss';
import './shared/i18n/i18n';
import { AuthProvider } from './shared/providers/authentication/AuthProvider';
import { FirebaseProvider } from './shared/providers/authentication/FirebaseAppProvider';
import { ConfigProvider } from './shared/providers/config/ConfigProvider';
import { LanguageProvider } from './shared/providers/utils/LanguageProvider';
import { LoadingProvider } from './shared/providers/utils/LoadingSpinnerProvider';
import { SessionExpiredProvider } from './shared/providers/utils/SessionExpiredProvider';
import { SnackBarProvider } from './shared/providers/utils/SnackBarProvider';
import store from './shared/store/store';
import { appTheme } from './theme/mui.theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={appTheme}>
      <Provider store={store}>
        <ConfigProvider>
          <FirebaseProvider>
            <LanguageProvider>
              <LoadingProvider>
                <AuthProvider>
                  <SessionExpiredProvider>
                    <SnackBarProvider>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </SnackBarProvider>
                  </SessionExpiredProvider>
                </AuthProvider>
              </LoadingProvider>
            </LanguageProvider>
          </FirebaseProvider>
        </ConfigProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
