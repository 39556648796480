import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridRowId,
  GridRowModes,
  GridRowModesModel
} from '@mui/x-data-grid';
import React from 'react';

function ActionsResponse(
  id: GridRowId,
  rowModesModel: GridRowModesModel,
  hasPermission: boolean,
  handleEditClick: (id: GridRowId) => () => void,
  handleCancelClick: (id: GridRowId) => () => void,
  handleSaveClick: (id: GridRowId) => () => void
): React.ReactElement<GridActionsCellItemProps>[] {
  const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

  if (hasPermission && isInEditMode) {
    return [
      <GridActionsCellItem
        key={id}
        icon={<CheckCircleIcon />}
        label="Save"
        sx={{
          color: 'primary.main'
        }}
        onClick={handleSaveClick(id)}
      />,
      <GridActionsCellItem
        key={id}
        icon={<CancelIcon />}
        label="Cancel"
        className="textPrimary"
        onClick={handleCancelClick(id)}
        color="inherit"
      />
    ];
  }

  return [
    <GridActionsCellItem
      key={id}
      icon={<EditIcon />}
      label="Edit"
      disabled={!hasPermission}
      className="textPrimary"
      onClick={handleEditClick(id)}
      color="inherit"
    />
  ];
}

export default ActionsResponse;
