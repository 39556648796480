import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import AppSuspense from './components/app-suspense/AppSuspense';
import { EditIntent } from './pages/entities/fulfillment/EditIntent';
import TemplateResponse from './pages/entities/fulfillment/TemplateResponse';
import ErrorPage from './pages/error/error-page/ErrorPage';
import PageWrapper from './pages/public/page-wrapper/PageWrapper';
import { UserPermissions } from './shared/data/enums/permissions-enum';
import { UserRoles } from './shared/data/enums/roles-enum';
import { RoutesEnum } from './shared/data/enums/routes-enum';
import PrivateRoute from './shared/providers/route/PrivateRoute';
import ProtectedRoute from './shared/providers/route/ProtectedRoute';
import PublicRoute from './shared/providers/route/PublicRoute';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const Home = React.lazy(() => import('./pages/entities/home/Home'));
const NewGroup = React.lazy(() => import('./pages/entities/groups/new-group/NewGroup'));
const DetailGroup = React.lazy(() => import('./pages/entities/groups/detail/DetailGroup'));
const NewUser = React.lazy(() => import('./pages/entities/users/new-user/NewUser'));
const DetailUser = React.lazy(() => import('./pages/entities/users/detail/DetailUser'));
const Login = React.lazy(() => import('./pages/public/login/Login'));
const ResetPassword = React.lazy(() => import('./pages/public/reset-password/ResetPassword'));
const UserProfile = React.lazy(() => import('./pages/entities/users/profile/UserProfile'));
const UserList = React.lazy(() => import('./pages/entities/users/user-list/UserList'));
const ListGroups = React.lazy(() => import('./pages/entities/groups/list-groups/ListGroups'));
const ApiKeyList = React.lazy(() => import('./pages/entities/apikey/apikey-list/ApiKeyList'));
const NewApiKey = React.lazy(() => import('./pages/entities/apikey/new-apikey/NewApiKey'));
const DetailApiKey = React.lazy(() => import('./pages/entities/apikey/detail/DetailApiKey'));
const DetailAnalytics = React.lazy(() => import('./pages/entities/analytics/detail/DetailAnalytics'));
const UploadComponentXlsx = React.lazy(
  () => import('./pages/entities/ingestion/components/upload/UploadComponentXlsx')
);
export const routes: RouteObject[] = [
  {
    path: RoutesEnum.LANDING_PAGE,
    errorElement: (
      <AppSuspense>
        <ErrorPage />
      </AppSuspense>
    ),
    element: <PageWrapper />,
    children: [
      {
        path: '',
        index: true,
        element: <></>
      },
      {
        path: '/',
        element: (
          <PrivateRoute>
            <AppSuspense>
              <Outlet />
            </AppSuspense>
          </PrivateRoute>
        ),
        children: [
          {
            path: RoutesEnum.DASHBOARD,
            element: <Home />
          },
          {
            path: RoutesEnum.PROFILE_USER,
            element: <UserProfile />
          },
          {
            path: RoutesEnum.LIST_USERS,
            element: (
              <ProtectedRoute roles={[UserRoles.ADMIN, UserRoles.GROUP_ADMIN]}>
                <Outlet />
              </ProtectedRoute>
            ),
            children: [
              {
                path: '',
                element: <UserList />
              },
              {
                path: RoutesEnum.CREATE_USER,
                element: <NewUser />
              },
              {
                path: RoutesEnum.DETAIL_USER,
                element: <DetailUser isEdit={false} />
              },
              {
                path: RoutesEnum.UPDATE_USER,
                element: <DetailUser isEdit={true} />
              }
            ]
          },
          {
            path: RoutesEnum.LIST_API_KEY,
            element: (
              <ProtectedRoute roles={[UserRoles.ADMIN]}>
                <Outlet />
              </ProtectedRoute>
            ),
            children: [
              {
                path: '',
                element: <ApiKeyList />
              },
              {
                path: RoutesEnum.CREATE_API_KEY,
                element: <NewApiKey />
              },
              {
                path: RoutesEnum.DETAIL_API_KEY,
                element: <DetailApiKey isEdit={false} />
              },
              {
                path: RoutesEnum.UPDATE_API_KEY,
                element: <DetailApiKey isEdit={true} />
              }
            ]
          },
          {
            path: RoutesEnum.LIST_API_KEY,
            element: (
              <ProtectedRoute roles={[UserRoles.ADMIN]}>
                <Outlet />
              </ProtectedRoute>
            ),
            children: [
              {
                path: '',
                element: <ApiKeyList />
              }
            ]
          },
          {
            path: RoutesEnum.LIST_GROUP,
            element: (
              <ProtectedRoute roles={[UserRoles.ADMIN]}>
                <Outlet />
              </ProtectedRoute>
            ),
            children: [
              {
                path: '',
                element: (
                  <>
                    <ListGroups />
                  </>
                )
              },
              {
                path: RoutesEnum.CREATE_GROUP,
                element: <NewGroup />
              },
              {
                path: RoutesEnum.DETAIL_GROUP,
                element: <DetailGroup isEdit={false} />
              },
              {
                path: RoutesEnum.UPDATE_GROUP,
                element: <DetailGroup isEdit={true} />
              }
            ]
          },
          {
            path: RoutesEnum.LIST_INGESTION,
            element: (
              <ProtectedRoute>
                <UploadComponentXlsx />
              </ProtectedRoute>
            )
          },
          {
            path: RoutesEnum.DETAIL_ANALYTICS,
            element: (
              <ProtectedRoute
                roles={[UserRoles.ADMIN, UserRoles.GROUP_ADMIN, UserRoles.USER]}
                permissions={[UserPermissions.ANALYTICS_EDITOR, UserPermissions.ANALYTICS_VIEWER]}>
                <Outlet />
              </ProtectedRoute>
            ),
            children: [
              {
                path: '',
                element: <DetailAnalytics />
              }
            ]
          },
          {
            path: RoutesEnum.LIST_FULFILLMENT,
            element: (
              <ProtectedRoute
                roles={[UserRoles.ADMIN, UserRoles.GROUP_ADMIN, UserRoles.USER]}
                permissions={[UserPermissions.ANALYTICS_EDITOR, UserPermissions.ANALYTICS_VIEWER]}>
                <Outlet />
              </ProtectedRoute>
            ),
            children: [
              {
                path: '',
                element: <TemplateResponse />
              },
              {
                path: RoutesEnum.FULFILLMENT_UPDATE,
                element: <EditIntent />
              }
            ]
          }
        ]
      },
      {
        path: '*',
        element: (
          <AppSuspense>
            <ErrorPage />
          </AppSuspense>
        )
      }
    ]
  },
  {
    path: RoutesEnum.LOGIN,
    element: (
      <PublicRoute>
        <AppSuspense>
          <Outlet />
        </AppSuspense>
      </PublicRoute>
    ),
    children: [
      {
        path: '',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Login />
          },
          {
            path: RoutesEnum.RESET_PASSWORD,
            element: <ResetPassword />
          }
        ]
      }
    ]
  }
];
