import { format as formatData, isDate, Locale } from 'date-fns';
import { enGB, it } from 'date-fns/locale';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import _ from 'lodash';
import { initReactI18next } from 'react-i18next';
import { SupportedLanguageEnum } from '../data/enums/supported-language-enum';

const locales: { [key: string]: Locale } = {
  [SupportedLanguageEnum.EN]: enGB,
  [SupportedLanguageEnum.IT]: it
};
const defaultLocale: Locale = it;
const defaultFormat = 'dd/MM/YYYY';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: SupportedLanguageEnum.IT,
    debug: _.isEqual(process.env.REACT_APP_DEBUG, 'true'),
    defaultNS: 'common',
    fallbackNS: 'common',
    nsSeparator: '::',
    ns: 'common',
    interpolation: {
      escapeValue: false,
      format: (value: Date, format: string | undefined, lng: string | undefined): string => {
        if (isDate(value)) {
          const locale = !_.isNil(lng) ? locales[lng] : defaultLocale;
          return formatData(value, format ?? defaultFormat, { locale });
        } else {
          return '';
        }
      }
    }
  });

export default i18n;
