import { createSelector } from '@reduxjs/toolkit';
import { IApplicationState } from '../models/application-state';
import { RootState } from '../store';

export const selectAppConfig = (state: RootState): IApplicationState => state.application;

export const currentDrawerState = createSelector(
  selectAppConfig,
  (state: IApplicationState): boolean => state.drawerOpen
);

export const selectedLanguage = createSelector(
  selectAppConfig,
  (state: IApplicationState): string => state.currentLanguage
);
