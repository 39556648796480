import { SettingDTO } from '../../data/dto/setting-dto';
import { useConfigProvider } from '../../providers/config/useConfigProvider';
import useCustomAxios from '../interceptors/useCustomAxios';

interface IAxiosSettings {
  get(groupId: string): Promise<SettingDTO>;
}

export default function useAxiosSettings(): IAxiosSettings {
  const { firebase } = useConfigProvider();
  const { axios } = useCustomAxios();
  const settingsUrl = `${firebase.baseUrl}/settings`;

  const get = async (groupId: string): Promise<SettingDTO> => {
    const res = await axios.get(`${settingsUrl}/${groupId}`);
    return res.data;
  };

  return { get };
}
